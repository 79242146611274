import { Mic } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import DurationControl from "./DurationControl";
import SensitivityControl from "./SensitivityControl";
import TelegramSettings from "./TelegramSettings";
import TimeScheduleControl from "./TimeScheduleControl";

export default function AudioMeter() {
  const [isListening, setIsListening] = useState(false);
  const [isLoud, setIsLoud] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [sensitivity, setSensitivity] = useState(() => {
    const saved = localStorage.getItem("soundSensitivity");
    return saved ? Number(saved) : 30; // Lower default sensitivity
  });
  const [duration, setDuration] = useState(() => {
    const saved = localStorage.getItem("alertDuration");
    return saved ? Number(saved) : 1;
  });
  const [botToken, setBotToken] = useState(() => {
    return localStorage.getItem("telegramBotToken") || "";
  });
  const [chatId, setChatId] = useState(() => {
    return localStorage.getItem("telegramChatId") || "";
  });
  const [startTime, setStartTime] = useState(() => {
    return localStorage.getItem("notificationStartTime") || "07:00";
  });
  const [endTime, setEndTime] = useState(() => {
    return localStorage.getItem("notificationEndTime") || "19:00";
  });
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  // Refs to track loud sound duration and notification state
  const loudStartTime = useRef<number | null>(null);
  const hasNotifiedForCurrentLoudEvent = useRef(false);

  const handleSensitivityChange = (value: number) => {
    setSensitivity(value);
    localStorage.setItem("soundSensitivity", value.toString());
  };

  const handleDurationChange = (value: number) => {
    setDuration(value);
    localStorage.setItem("alertDuration", value.toString());
  };

  const handleBotTokenChange = (token: string) => {
    setBotToken(token);
    localStorage.setItem("telegramBotToken", token);
  };

  const handleChatIdChange = (id: string) => {
    setChatId(id);
    localStorage.setItem("telegramChatId", id);
  };

  const handleStartTimeChange = (time: string) => {
    setStartTime(time);
    localStorage.setItem("notificationStartTime", time);
  };

  const handleEndTimeChange = (time: string) => {
    setEndTime(time);
    localStorage.setItem("notificationEndTime", time);
  };

  const now = new Date();
  const currentTime =
    now.getHours().toString().padStart(2, "0") +
    ":" +
    now.getMinutes().toString().padStart(2, "0");

  const isWithinScheduledTime = () => {
    const parseTime = (timeStr: string) => {
      const [hours, minutes] = timeStr.split(':').map(Number);
      return hours * 60 + minutes;
    };

    const currentMinutes = parseTime(currentTime);
    const startMinutes = parseTime(startTime);
    const endMinutes = parseTime(endTime);

    // If end time is less than start time, it means the range crosses midnight
    if (endMinutes < startMinutes) {
      // Check if current time is either after start time OR before end time
      return currentMinutes >= startMinutes || currentMinutes <= endMinutes;
    }
    
    // Normal case where start time is before end time
    return currentMinutes >= startMinutes && currentMinutes <= endMinutes;
  };

  const isTelegramInfoAdded = () => {
    return botToken || chatId;
  };

  const sendTelegramNotification = async () => {
    if (!isTelegramInfoAdded) {
      const error =
        "Missing Telegram credentials. Please set your Bot Token and Chat ID in the settings.";
      console.error(error);
      return;
    }

    // Check if current time is within scheduled time
    if (!isWithinScheduledTime()) {
      console.log("Outside of scheduled notification time");
      return;
    }

    const message = `👶🏻 Baby is crying!`;
    const isProduction =
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "127.0.0.1";

    try {
      let response;

      if (isProduction) {
        console.log("Sending notification via API route");
        const apiUrl = new URL(
          "/api/telegram",
          window.location.origin
        ).toString();

        response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            botToken,
            chatId,
            message,
          }),
        });
      } else {
        console.log("Sending notification directly to Telegram API");
        const telegramUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

        response = await fetch(telegramUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
            parse_mode: "HTML",
          }),
        });
      }

      const responseText = await response.text();
      console.log("Response:", {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries()),
        body: responseText,
      });

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        throw new Error(`Failed to parse response: ${responseText}`);
      }

      if (!response.ok || data.error) {
        const errorMessage = data.error
          ? `${data.error}: ${JSON.stringify(data.details)}`
          : "Failed to send notification";
        throw new Error(errorMessage);
      }

      console.log("Notification sent successfully:", data);
    } catch (err) {
      console.error("Error sending notification:", err);
    }
  };

  useEffect(() => {
    if (!window.isSecureContext) {
      return;
    }

    let audioContext: AudioContext;
    let analyzer: AnalyserNode;
    let microphone: MediaStreamAudioSourceNode;
    let animationFrame: number;

    const startListening = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        audioContext = new AudioContext();
        analyzer = audioContext.createAnalyser();
        microphone = audioContext.createMediaStreamSource(stream);

        microphone.connect(analyzer);
        analyzer.fftSize = 256;

        const bufferLength = analyzer.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const checkSound = () => {
          analyzer.getByteFrequencyData(dataArray);
          // Calculate average sound level (0-255)
          const average = dataArray.reduce((a, b) => a + b) / bufferLength;
          // Map sensitivity (0-100) to a reasonable threshold (0-255)
          const threshold = (sensitivity / 100) * 50; // Adjust max threshold to 50 instead of 255
          const currentlyLoud = average > threshold;

          setIsLoud(currentlyLoud);

          if (currentlyLoud) {
            if (loudStartTime.current === null) {
              console.log("Sound became loud");
              loudStartTime.current = Date.now();
              hasNotifiedForCurrentLoudEvent.current = false;
            } else {
              const loudDuration = (Date.now() - loudStartTime.current) / 1000;
              console.log("Sound is still loud:", {
                duration: loudDuration,
                requiredDuration: duration,
                hasNotified: hasNotifiedForCurrentLoudEvent.current,
              });

              if (
                loudDuration >= duration &&
                !hasNotifiedForCurrentLoudEvent.current
              ) {
                console.log("Triggering notification");
                sendTelegramNotification();
                hasNotifiedForCurrentLoudEvent.current = true;
              }
            }
          } else {
            if (loudStartTime.current !== null) {
              console.log("Sound is no longer loud");
            }
            loudStartTime.current = null;
            hasNotifiedForCurrentLoudEvent.current = false;
          }

          animationFrame = requestAnimationFrame(checkSound);
        };

        checkSound();
        setIsListening(true);
      } catch (err) {
        setIsListening(false);
      }
    };

    startListening();

    return () => {
      if (audioContext) {
        audioContext.close();
      }
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [sensitivity, duration]);

  return (
    <div className="flex flex-col items-center gap-6 w-full max-w-md">
      <div
        className={`p-8 rounded-full transition-colors duration-300 ${
          isListening ? "bg-purple-100" : "bg-gray-100"
        } ${isLoud ? "bg-red-300" : ""}`}
      >
        <Mic
          size={48}
          className={`transition-colors duration-300 ${
            isListening ? "text-purple-500" : "text-gray-400"
          } ${isLoud ? "text-red-900" : ""}`}
        />
      </div>
      {isWithinScheduledTime() && isTelegramInfoAdded() ? (
        <div className="text-gray-700">
          You will be notified when baby is crying
        </div>
      ) : isTelegramInfoAdded() ? (
        <div className="text-red-300 text-center">
          You will not be notified since the time is outside of your
          notification schedule
        </div>
      ) : (
        <div className="text-red-500 text-center">
          <span
            onClick={() => setIsSettingsOpen(true)}
            className="underline cursor-pointer"
          >
            Add your Telegram credentials
          </span>
        </div>
      )}

      {/* <div className="bg-purple-100 p-4 rounded-lg"> */}
      <div
        className={`${
          isSettingsVisible ? "bg-purple-100" : ""
        } p-4 rounded-lg `}
      >
        <div className="w-full space-y-4">
          <h2
            className="text-lg font-semibold text-center cursor-pointer hover:text-purple-600 transition-colors"
            onClick={() => setIsSettingsVisible(!isSettingsVisible)}
          >
            Settings
          </h2>
          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              isSettingsVisible
                ? "max-h-[500px] opacity-100"
                : "max-h-0 opacity-0"
            }`}
          >
            <div className="space-y-4">
              <SensitivityControl
                sensitivity={sensitivity}
                onChange={handleSensitivityChange}
              />
              <DurationControl
                duration={duration}
                onChange={handleDurationChange}
              />
              <TimeScheduleControl
                startTime={startTime}
                endTime={endTime}
                onStartTimeChange={handleStartTimeChange}
                onEndTimeChange={handleEndTimeChange}
              />
              <button
                onClick={() => setIsSettingsOpen(true)}
                className="w-full flex items-center justify-center gap-2 bg-white text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Telegram Settings
              </button>
            </div>
          </div>
        </div>
        <TelegramSettings
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          botToken={botToken}
          chatId={chatId}
          onBotTokenChange={handleBotTokenChange}
          onChatIdChange={handleChatIdChange}
        />
      </div>
    </div>
  );
}

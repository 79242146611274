import { CalendarClock } from "lucide-react";

interface TimeScheduleControlProps {
  startTime: string;
  endTime: string;
  onStartTimeChange: (time: string) => void;
  onEndTimeChange: (time: string) => void;
}

export default function TimeScheduleControl({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
}: TimeScheduleControlProps) {
  return (
    <div className="flex items-center gap-4 bg-white p-4 rounded-lg shadow-sm">
      <CalendarClock size={20} className="text-gray-500" />
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex justify-between items-center">
          <label className="text-sm font-medium text-gray-700">
            Notification Schedule
          </label>
        </div>
        <div className="flex gap-4 items-center">
          <div className="flex-1">
            <label
              htmlFor="startTime"
              className="block text-xs text-gray-500 mb-1"
            >
              Start Time
            </label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => onStartTimeChange(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md text-sm"
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="endTime"
              className="block text-xs text-gray-500 mb-1"
            >
              End Time
            </label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={(e) => onEndTimeChange(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md text-sm"
            />
          </div>
        </div>
        <p className="text-xs text-gray-500">
          Notifications will only be sent during this time period
        </p>
      </div>
    </div>
  );
}

import { Activity } from "lucide-react";

interface DurationControlProps {
  duration: number;
  onChange: (value: number) => void;
}

export default function DurationControl({
  duration,
  onChange,
}: DurationControlProps) {
  return (
    <div className="flex items-center gap-4 bg-white p-4 rounded-lg shadow-sm">
      <Activity size={20} className="text-gray-500" />
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex justify-between">
          <label
            htmlFor="duration"
            className="text-sm font-medium text-gray-700"
          >
            Alert Duration
          </label>
          <span className="text-sm text-gray-500">{duration} seconds</span>
        </div>
        <input
          type="range"
          id="duration"
          min="1"
          max="10"
          value={duration}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-500"
        />
        <p className="text-xs text-gray-500">
          Send notification after sound is loud for this long
        </p>
      </div>
    </div>
  );
}

import { useState } from "react";
import AudioMeter from "./components/AudioMeter";
import "./index.css";

function App() {
  const [showAbout, setShowAbout] = useState(false);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center gap-8 bg-slate-50 p-6 relative">
      <button
        onClick={() => setShowAbout(true)}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        aria-label="About"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </button>

      {showAbout && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          onClick={() => setShowAbout(false)}
        >
          <div
            className="bg-white rounded-lg p-6 max-w-lg relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setShowAbout(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-xl font-bold mb-4">About Cry Notify</h2>
            <div className="text-gray-600 space-y-4">
              <p>
                Hey! My name is{" "}
                <a href="https://kaplich.me/" className="underline">
                  Sergey Kaplich
                </a>
                .
              </p>
              <p>
                In the evenings, I usually sit in another room with my
                headphones on, working on different projects. But I always worry
                about missing it when my daughter starts crying in the other
                room, while my wife is asleep next to her.{" "}
              </p>
              <p>
                That's why I created{" "}
                <a href="https://crynotify.xyz" className="underline">
                  Cry Notify
                </a>
                .
              </p>

              <p>
                I open this website on my old phone, plug it into a socket, and
                place it under the bed. Whenever my daughter starts crying, I
                get a notification on Telegram and can immediately go help her
                fall back asleep.
              </p>
              <p>You can use it too. Hope it helps!</p>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-3xl font-bold text-gray-800">👶🏻 Cry Notify</h1>
      <p className="text-sm text-gray-500 max-w-xs text-center">
        Cry Notify detects when sounds are loud and sends Telegram
        notifications.
      </p>
      <AudioMeter />
      <div className="text-xs text-gray-500 max-w-xs text-center">
        made with ❤️ by{" "}
        <a href="https://kaplich.me" className="underline">
          Sergey Kaplich
        </a>
        <p className="mt-2">
          <a href="https://buymeacoffee.com/kaplich" className="underline">
            buy me a coffee
          </a>
        </p>
        <p className="mt-2">
          check out{" "}
          <a href="https://www.growbluesky.com/" className="underline">
            grow bluesky
          </a>{" "}
          — collection of tools for bluesky users
        </p>
      </div>
    </div>
  );
}

export default App;

import { SendHorizontal, X } from "lucide-react";
import { useState } from "react";

interface TelegramSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  botToken: string;
  chatId: string;
  onBotTokenChange: (token: string) => void;
  onChatIdChange: (chatId: string) => void;
}

export default function TelegramSettings({
  isOpen,
  onClose,
  botToken,
  chatId,
  onBotTokenChange,
  onChatIdChange,
}: TelegramSettingsProps) {
  const [testStatus, setTestStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState("");

  if (!isOpen) return null;

  const handleSaveAndTest = async () => {
    if (!botToken || !chatId) {
      setTestStatus("error");
      setErrorMessage("Please fill in both Bot Token and Chat ID");
      return;
    }

    setTestStatus("loading");
    const message = "🔔 Test notification from Sound Alert app";
    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send test message");
      }

      setTestStatus("success");
      setErrorMessage("");
    } catch (err) {
      setTestStatus("error");
      setErrorMessage(
        "Failed to send test message. Please check your credentials."
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X size={20} />
        </button>

        <h2 className="text-xl font-semibold mb-6">Telegram Settings</h2>

        <div className="space-y-4">
          <div>
            <label
              htmlFor="botToken"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Bot Token
            </label>
            <input
              type="password"
              id="botToken"
              value={botToken}
              onChange={(e) => onBotTokenChange(e.target.value)}
              placeholder="Enter your bot token"
              className="w-full p-2 border border-gray-300 rounded-md text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="chatId"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Chat ID
            </label>
            <input
              type="text"
              id="chatId"
              value={chatId}
              onChange={(e) => onChatIdChange(e.target.value)}
              placeholder="Enter your chat ID"
              className="w-full p-2 border border-gray-300 rounded-md text-sm"
            />
          </div>

          <p className="text-xs text-gray-500">
            Create a bot via @BotFather and get your chat ID via @userinfobot
          </p>

          <button
            onClick={handleSaveAndTest}
            disabled={testStatus === "loading"}
            className="w-full flex items-center justify-center gap-2 bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <SendHorizontal size={16} />
            {testStatus === "loading" ? "Testing..." : "Save & Test Settings"}
          </button>

          {testStatus === "success" && (
            <p className="text-sm text-green-600">
              ✓ Test message sent successfully! Check your Telegram.
            </p>
          )}

          {testStatus === "error" && (
            <p className="text-sm text-red-600">✗ {errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
}

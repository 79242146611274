import { Settings2 } from "lucide-react";

interface SensitivityControlProps {
  sensitivity: number;
  onChange: (value: number) => void;
}

export default function SensitivityControl({
  sensitivity,
  onChange,
}: SensitivityControlProps) {
  return (
    <div className="flex items-center gap-4 bg-white p-4 rounded-lg shadow-sm">
      <Settings2 size={20} className="text-gray-500" />
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex justify-between">
          <label
            htmlFor="sensitivity"
            className="text-sm font-medium text-gray-700"
          >
            Sound Sensitivity
          </label>
          <span className="text-sm text-gray-500">
            {sensitivity < 30
              ? "More Sensitive"
              : sensitivity > 70
              ? "Less Sensitive"
              : "Medium"}
          </span>
        </div>
        <input
          type="range"
          id="sensitivity"
          min="0"
          max="100"
          value={sensitivity}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-500"
        />
        <p className="text-xs text-gray-500">
          {sensitivity < 30
            ? "Will detect quieter sounds"
            : sensitivity > 70
            ? "Will only detect very loud sounds"
            : "Balanced detection"}
        </p>
      </div>
    </div>
  );
}
